<template>
  <header ref="navbarRef" class="site-header h-20 lg:h-25">
    <nav :class="[ 'w-full h-20 lg:h-25 p-4 lg:p-5 top-0 end-0 z-20 transition-transform duration-300 bg-primary-dark', true ? 'is-sticky fixed' : 'fixed lg:absolute' ]">
      <div class="flex justify-between items-center px-3 lg:px-10 h-full">
        <ShoppingBagBtn class="mr-5 lg:hidden" />
        <Logo class="mx-auto lg:mx-0" variant="color" />
        <MobileBurgerButton class="ml-5 lg:hidden" />
        <ul class="hidden lg:flex items-center flex-shrink-0 space-s-5">
          <li v-for="{ routeCode, label, className } in siteSettings.headerLinks" :key="`${routeCode}_${label}`" :class="className">
            <NavLink
              :href="translatePath(routeCode)"
              :aria-label="$t(label)"
              active-class="text-accent-dark"
              inactive-class="text-light"
              class="no-underline font-bold flex items-center transition-colors duration-200 hover:text-accent-dark-hover focus:text-accent-dark-hover mr-5"
            >
              <span>{{ $t(label) }}</span>
            </NavLink>
          </li>
          <li class="pr-5">
            <AddAdButton />
          </li>
          <li>
            <InspirationButton />
          </li>
          <li>
            <FavoritesButton />
          </li>
          <li v-if="false">
            <NotificationsButton />
          </li>
          <li>
            <ShoppingBagBtn />
          </li>
          <li v-if="auth.isLoggedIn">
            <AuthorizedMenu />
          </li>
          <li v-else>
            <JoinButton />
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { siteSettings } from "@settings/site.settings";
import { useAuthStore } from "@stores/auth";
import Logo from "@components/ui/logo";
import NavLink from "@components/ui/link/nav-link";
import AddAdButton from "@components/layout/navbar/add-ad-button";
import AuthorizedMenu from "@components/layout/navbar/authorized-menu";
import JoinButton from "@components/layout/navbar/join-button";
import ShoppingBagBtn from "@components/cart/shopping-bag-btn";
import FavoritesButton from "@components/layout/navbar/favorites-button";
import NotificationsButton from "@components/layout/navbar/notifications-button";
import InspirationButton from "@components/layout/navbar/inspiration-button";
import MobileBurgerButton from "@components/layout/navbar/mobile-burger-button";

const auth = useAuthStore();

</script>
